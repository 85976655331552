import _ from 'lodash'
// import update from 'immutability-helper';

export const initialState = {
	fetching: true,
	isError: false,
	limit: 10,
	results: [],
	filters: {},
	sortable_fields: {},
	sorting_details: {},
	enable_total_contract_value: false,
	total_contract_value: 0,
	totalRecords: 0,
}

export function reducer(state = initialState, action) {
	switch (action.type) {
		case 'fetchResultsPending':
			return { ...state, results: [], isError: false, fetching: true }
		case 'fetchResultsCompleted':
			return {
				...state,
				fetching: false,
				results: _.get(action, 'data.data.discoverx_results', []),
				sortable_fields: _.get(action, 'data.data.sortable_fields', []),
				sorting_details: _.get(action, 'data.data.sorting_details', []),
				enable_total_contract_value: action?.data?.data?.enable_total_contract_value || false,
				total_contract_value: action?.data?.data?.total_contract_value || 0,
				totalRecords: action?.data?.data?.total_records || 0,
			}
		case 'fetchResultsError':
			return {
				...state,
				fetching: false,
				results: [],
				isError: true,
			}
		case 'resetState':
			return initialState
		default:
			throw new Error()
	}
}
