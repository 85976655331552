import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { Grid, InputAdornment, MenuItem, TextField, Typography, Select, makeStyles } from '@material-ui/core'
import * as Yup from 'yup'
import { Divider } from '@material-ui/core'
import { STICKY_REMINDER_LIST } from 'constants/index'

const useStyles = makeStyles((theme) => ({
	selectBox: {
		padding: theme.spacing(1.2, 4, 1.2, 2),
	},
	dividerWrapper: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}))

const ActivitySchema = Yup.object().shape({
	sticky_escalation: Yup.number()
		.typeError('Please enter the duration in numbers (Hours).')
		.min(1, 'Minimum value should be 1')
		//.max(50, 'Too Long!')
		.required('Please enter the pipeline escalation duration in numbers (Hours).'),
})

const StickyEscForm = React.forwardRef(({ isViewMode, initialValues, onSubmit }, ref) => {
	const classes = useStyles()

	const formik = useFormik({
		initialValues: initialValues || {
			sticky_escalation: '',
			sticky_reminder: '',
		},
		onSubmit: onSubmit,
		validationSchema: ActivitySchema,
	})

	useEffect(() => {
		ref.current = formik
		return () => {
			ref.current = null
		}
	}, [ref, formik])

	return (
		<form ref={ref} onSubmit={formik.handleSubmit}>
			<Grid container spacing={1}>
				<Grid item xs={12} md={8}>
					<Typography variant='body1'>PipeLine Escalation Duration</Typography>
					<Typography color='textSecondary' variant='caption' gutterBottom>
						This setting will automatically escalate jobs to reporting team unit in a defined duration after the job due date exceeds its closure.
					</Typography>
				</Grid>
				<Grid container item xs={12} md={4} alignItems='center'>
					<TextField
						name='sticky_escalation'
						value={formik.values.sticky_escalation}
						error={Boolean(formik.errors.sticky_escalation)}
						helperText={formik.errors?.sticky_escalation}
						onChange={formik.handleChange}
						id='sticky-esc'
						variant='outlined'
						fullWidth
						size='small'
						inputProps={{ maxLength: 4 }}
						InputProps={{
							endAdornment: <InputAdornment position='start'>Hours</InputAdornment>,
							readOnly: isViewMode,
						}}
					/>
				</Grid>

				<Grid item xs={12} className={classes.dividerWrapper}>
					<Divider fullWidth />
				</Grid>

				<Grid item xs={12} md={8}>
					<Typography variant='body1'>Due Date Reminder</Typography>
					<Typography color='textSecondary' variant='caption' gutterBottom>
						Reminders will be shown to all the members of this GlassX board{' '}
					</Typography>
				</Grid>
				<Grid container item xs={12} md={4} alignItems='center'>
					<Select
						name='sticky_reminder'
						id='sticky_reminder'
						value={formik.values.sticky_reminder}
						displayEmpty
						classes={{ root: classes.selectBox }}
						error={Boolean(formik.errors.sticky_reminder)}
						helperText={formik.errors?.sticky_reminder}
						onChange={formik.handleChange}
						readOnly={isViewMode}
						variant='outlined'
						size='small'
						fullWidth
					>
						{STICKY_REMINDER_LIST.map((item) => (
							<MenuItem dense value={item.value}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</Grid>
			</Grid>
		</form>
	)
})

StickyEscForm.propTypes = {
	isViewMode: PropTypes.bool.isRequired,
	initialValues: PropTypes.shape({
		sticky_escalation: PropTypes.string.isRequired,
	}),
	onSubmit: PropTypes.func.isRequired,
}

export default StickyEscForm
