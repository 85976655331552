import React, { useReducer, useRef, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	Slide,
	TextField,
	Typography,
	useMediaQuery,
	Icon,
	useTheme,
	Grid,
	Link,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import * as Yup from 'yup'
import { Formik } from 'formik'
import _ from 'lodash'
import { initialState, reducer } from './reducer'
import GlassApi from '../../services/glass/api'
import { useDispatch } from 'react-redux'
import { showLoader, hideLoader, showErrorMessage } from 'services/loader/actions'
import Axios from 'axios'
import { getErrMsg, getSuccessMsg } from 'utils'
import AddMemberDialog from 'components/AddMemberDialog'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import classnames from 'classnames'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import ProjectPopup from '../../scences/Projects/FormDialog'
import { usePermission } from 'hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
	dialog: {
		minWidth: 400,
		[theme.breakpoints.down('sm')]: {
			minWidth: 'unset',
		},
	},
	tab: {
		textTransform: 'none',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	customTabTitle: {
		fontSize: 14,
		fontWeight: '500',
		[theme.breakpoints.down('xs')]: {
			fontSize: 13,
		},
	},
	dialogTitle: {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogContent: {
		paddingTop: theme.spacing(2),
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	textField: {
		paddingBottom: theme.spacing(2),
		'& .Mui-disabled': {
			color: theme.palette.almostBlack[700],
		},
	},
	container: {
		textAlign: 'center',
		padding: theme.spacing(2),
	},
	loaderTxt: {
		marginTop: theme.spacing(1.5),
	},
	alertIcon: {
		color: theme.palette.warning.main,
		fontSize: theme.typography.pxToRem(50),
	},
	successIcon: {
		color: '#82c43c',
		fontSize: theme.typography.pxToRem(50),
	},
	actionBtn: {
		marginTop: theme.spacing(4),
		marginRight: theme.spacing(2),
	},
	actionBtnTop: {
		marginTop: theme.spacing(4),
	},
	subtitleTxt: {
		marginTop: theme.spacing(1.5),
	},
	linkContainer: {
		textAlign: 'right',
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(1),
		},
	},
	link: {
		textAlign: 'right',
		cursor: 'pointer',
	},
}))

function GlassFormDialog({ sessionId, open, history, handleClose, selectedData, refreshList }) {
	const classes = useStyles()
	const theme = useTheme()
	const formRef = useRef()
	const reduxDispatch = useDispatch()
	const projApiCancelExec = useRef()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const [state, dispatch] = useReducer(reducer, initialState)
	const [openDialog, setOpenDialog] = useState(false)
	const [glassCode, setGlassCode] = useState('')
	const [openConfirmCode, setOpenConfirmCode] = useState(false)
	const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
	const [finalData, setFinalData] = useState({})
	const { projectList, isFetchingProjects } = state
	const selectedGlassCode = _.get(selectedData, 'glass_code', '')
	const isEditMode = !_.isEmpty(selectedGlassCode)

	const initialValues = {
		glass_name: _.get(selectedData, 'glass_name', ''),
		glass_desc: _.get(selectedData, 'glass_desc', ''),
		project_code: !_.isEmpty(selectedData) ? { project_code: selectedData?.project_code, project_name: selectedData?.project_name } : null,
	}

	const validationSchema = !isEditMode
		? Yup.object().shape({
				glass_name: Yup.string().trim().required('Please enter the Board name.'),
				project_code: Yup.object().nullable().required('Please select the Project.'),
		  })
		: Yup.object().shape({
				glass_name: Yup.string().trim().required('Please enter the Board name.'),
		  })

	const delayedProjQuery = useCallback(
		_.debounce((q, callback) => {
			callback(q)
		}, 300),
		[]
	)

	const handleCloseMembers = () => {
		setOpenDialog(false)
		setOpenSuccessDialog(true)
	}

	const onTypeProjectAutocomplete = useCallback(
		(e, inputValue) => {
			delayedProjQuery(inputValue, (q) => {
				dispatch({ type: 'fetchProjectsPending' })
				GlassApi.fetchProjectsList(
					q,
					0,
					10,
					'project_name',
					projApiCancelExec,
					(res) => {
						dispatch({
							type: 'fetchProjectsCompleted',
							data: _.get(res, 'data.data.projects', []),
						})
					},
					(err) => {
						if (!Axios.isCancel(err)) dispatch({ type: 'fetchProjectsCompleted', data: [] })
					}
				)
			})
		},
		[delayedProjQuery, dispatch]
	)

	const onClickCreateBtn = () => {
		formRef.current.submitForm()
	}

	const onClickConfirm = () => {
		apiCallByMethod(finalData)
		onCloseConfirmPopup()
	}

	const onClickOpenGlass = () => {
		setOpenSuccessDialog(false)
		history.push(`/glassx/view/${glassCode}`)
	}

	const apiCallByMethod = (formData) => {
		const hideLoaderDialog = () => {
			reduxDispatch(hideLoader())
		}
		const onSuccess = (resp) => {
			if (isEditMode) {
				hideLoaderDialog()
				handleClose()
				refreshList(_.get(resp, 'data.data'))
				reduxDispatch(showSnackbarWithTimeout(getSuccessMsg(resp, 'Updated Successfully'), 1500))
			} else {
				handleClose()
				hideLoaderDialog()
				setGlassCode(_.get(resp, 'data.data.glass_code', '1'))
				reduxDispatch(showSnackbarWithTimeout(getSuccessMsg(resp, 'Updated Successfully'), 1500))
				setOpenDialog(true)
			}
		}

		const onFailure = (err) => {
			reduxDispatch(
				showErrorMessage(getErrMsg(err), 'Close', () => {
					hideLoaderDialog()
				})
			)
		}
		reduxDispatch(showLoader(!isEditMode ? 'Creating Board...' : 'Updating Board...'))
		!isEditMode
			? GlassApi.createGlass(formData, sessionId).then(onSuccess, onFailure)
			: GlassApi.updateGlass(formData, selectedGlassCode, sessionId).then(onSuccess, onFailure)
	}

	const onSubmit = (values) => {
		const formData = !_.isEditMode
			? { ...values, glass_name: _.trim(_.get(values, 'glass_name')), project_code: _.get(values, 'project_code.project_code') }
			: { glass_name: _.trim(_.get(values, 'glass_name', '')), glass_desc: _.get(values, 'glass_desc', '') }
		setFinalData(formData)

		!isEditMode ? apiCallByMethod(formData) : setOpenConfirmCode(true)
	}

	const onCloseConfirmPopup = () => {
		setOpenConfirmCode(false)
	}

	useEffect(() => {
		onTypeProjectAutocomplete({}, '')
	}, [onTypeProjectAutocomplete])

	const [openPopup, setOpenPopup] = useState(false)
	const projectPerms = usePermission(ROLE_MODULES.PROJECTS)
	const isCreateAllowed = React.useMemo(() => _.get(projectPerms, PERMISSION_TYPE.CREATE, false), [projectPerms])

	const togglePopup = () => {
		// if (open) {
		// 	handleClose()
		// }
		setOpenPopup(!openPopup)
	}

	return (
		<>
			<Dialog
				classes={{
					paper: classes.dialog,
				}}
				TransitionComponent={Transition}
				fullScreen={fullScreen}
				maxWidth={'sm'}
				open={open}
				onClose={handleClose}
				aria-labelledby='create-glass'
			>
				<ProjectPopup
					open={openPopup}
					handleClose={togglePopup}
					refreshData={onTypeProjectAutocomplete}
					action={PERMISSION_TYPE.CREATE}
					isEditAllowed={false}
				/>
				<DialogTitle className={classes.dialogTitle} disableTypography={true} id='create-glass-title'>
					<Typography variant='h6'>{isEditMode ? 'Edit Board' : 'Create Board'}</Typography>
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					<Formik innerRef={formRef} enableReinitialize={true} initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
						{(props) => {
							const { values, touched, errors, handleChange, handleBlur } = props
							return (
								<>
									<Grid container>
										<Grid item xs={12} className={classes.textField}>
											<TextField
												variant='outlined'
												margin='none'
												size='small'
												fullWidth
												name={'glass_name'}
												label={'Board Name*'}
												type='text'
												onChange={handleChange}
												onBlur={handleBlur}
												value={_.get(values, 'glass_name', '')}
												error={errors.glass_name && touched.glass_name}
												helperText={errors.glass_name && touched.glass_name && errors.glass_name}
												autoComplete='off'
												inputProps={{ maxLength: '64' }}
											/>
										</Grid>
										<Grid item xs={12} className={classes.textField}>
											<TextField
												variant='outlined'
												margin='none'
												size='small'
												fullWidth
												name={'glass_desc'}
												label={'Board Objectives and Key Results'}
												type='text'
												autoComplete='off'
												multiline
												rows={4}
												onChange={handleChange}
												onBlur={handleBlur}
												value={_.get(values, 'glass_desc', '')}
												inputProps={{ maxLength: '255' }}
											/>
										</Grid>
										<Grid item xs={12} className={classes.textField}>
											{isCreateAllowed ? (
												<Grid container alignItems='center' justifyContent='space-between'>
													<Grid item lg={9} xs={12}>
														<Autocomplete
															options={projectList}
															disabled={isEditMode}
															name='project_code'
															size={'small'}
															loading={isFetchingProjects}
															onChange={(e, value) => {
																handleChange({
																	target: {
																		name: 'project_code',
																		value,
																	},
																})
															}}
															onBlur={handleBlur}
															value={_.get(values, 'project_code', null)}
															classes={{
																root: classes.autocompleteRoot,
																inputRoot: classes.autoCompleteInputRoot,
															}}
															getOptionLabel={(option) => option.project_name || ''}
															getOptionSelected={(option, value) => option.project_code === value.project_code}
															loadingText='Fetching projects...'
															autoComplete
															onInputChange={onTypeProjectAutocomplete}
															renderInput={(params) => (
																<TextField
																	{...params}
																	name='project_code'
																	variant='outlined'
																	label={'Choose Project*'}
																	error={errors.project_code && touched.project_code}
																	helperText={errors.project_code && touched.project_code && errors.project_code}
																/>
															)}
														/>
													</Grid>
													<Grid item lg={3} xs={12} className={classes.linkContainer}>
														<Link className={classes.link} onClick={togglePopup}>
															+ New Project
														</Link>
													</Grid>
												</Grid>
											) : (
												<Autocomplete
													options={projectList}
													disabled={isEditMode}
													name='project_code'
													size={'small'}
													loading={isFetchingProjects}
													onChange={(e, value) => {
														handleChange({
															target: {
																name: 'project_code',
																value,
															},
														})
													}}
													onBlur={handleBlur}
													value={_.get(values, 'project_code', null)}
													classes={{
														root: classes.autocompleteRoot,
														inputRoot: classes.autoCompleteInputRoot,
													}}
													getOptionLabel={(option) => option.project_name || ''}
													getOptionSelected={(option, value) => option.project_code === value.project_code}
													loadingText='Fetching projects...'
													autoComplete
													onInputChange={onTypeProjectAutocomplete}
													renderInput={(params) => (
														<TextField
															{...params}
															name='project_code'
															variant='outlined'
															label={'Choose Project*'}
															error={errors.project_code && touched.project_code}
															helperText={errors.project_code && touched.project_code && errors.project_code}
														/>
													)}
												/>
											)}
										</Grid>
									</Grid>
								</>
							)
						}}
					</Formik>
				</DialogContent>
				<DialogActions className={classes.dialogFooter}>
					<Button variant='outlined' size={fullScreen ? 'small' : 'medium'} onClick={handleClose} color='primary'>
						Cancel
					</Button>
					<Button variant='contained' size={fullScreen ? 'small' : 'medium'} onClick={onClickCreateBtn} color='primary' disableElevation>
						{isEditMode ? 'Save' : 'Create'}
					</Button>
				</DialogActions>
			</Dialog>
			<AddMemberDialog open={openDialog} glassCode={glassCode} handleClose={handleCloseMembers} />
			<Dialog fullWidth={true} maxWidth='sm' open={openSuccessDialog} aria-labelledby='success Popup'>
				<DialogContent>
					<div className={classes.container}>
						<Icon className={classes.successIcon}>check_circle</Icon>
						<Typography className={classes.subtitleTxt} variant='body1'>
							{'The Board has been created successfully along with new Dashboard. You can view this on the DashX Screen'}
						</Typography>
						<Button variant='contained' color='primary' className={classnames(classes.actionBtnTop)} onClick={onClickOpenGlass} disableElevation>
							Open Board
						</Button>
						{/* <Button variant='contained' className={classnames(classes.actionBtn)} onClick={onCloseSuccessGlass} color='default' disableElevation>
							Cancel
						</Button> */}
					</div>
				</DialogContent>
			</Dialog>
			<Dialog fullWidth={true} maxWidth='xs' open={openConfirmCode} aria-labelledby='Loader Popup'>
				<DialogContent>
					<div className={classes.container}>
						<ViewColumnIcon className={classes.alertIcon} />
						<Typography className={classes.subtitleTxt} variant='body1'>
							{'Are you sure, you want to save changes?'}
						</Typography>
						<Button variant='contained' color='primary' className={classnames(classes.actionBtn)} onClick={onClickConfirm} disableElevation>
							Confirm
						</Button>
						<Button variant='contained' className={classnames(classes.actionBtn)} onClick={onCloseConfirmPopup} color='default' disableElevation>
							Cancel
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	)
}

GlassFormDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	selectedData: PropTypes.object,
	refreshList: PropTypes.func,
	sessionId: PropTypes.string,
}
export default GlassFormDialog
