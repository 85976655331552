import { ACTION_TYPES, FILTER_CONSTANTS, JOB_PRIORITY } from '../../../constants'
import _ from 'lodash'

const initialState = {
	styleCode: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	retailer: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	product: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	reseller: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	[FILTER_CONSTANTS.JOB_STATUS]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [], //["Investigation", "CFA", "NFA", "URL Removed", "Closed", "Open", "Escalated"]
	},
	[FILTER_CONSTANTS.TARGET_DAYS]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: { startDate: new Date(), endDate: new Date() },
	},
	[FILTER_CONSTANTS.DATE_RANGE]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: {
			startDate: new Date(new Date().setDate(new Date().getDate() - 20)),
			endDate: new Date(),
		},
		additionalProps: { maxDate: new Date() },
	},
	[FILTER_CONSTANTS.JOB_NAME]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	[FILTER_CONSTANTS.ASSIGNED_TO]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	[FILTER_CONSTANTS.PRIORITY]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [JOB_PRIORITY.HIGH, JOB_PRIORITY.LOW, JOB_PRIORITY.MEDIUM, JOB_PRIORITY.ESCALATED],
	},
	[FILTER_CONSTANTS.DISCOUNT]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [0, 100],
	},
	[FILTER_CONSTANTS.PRODUCT_NAME]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	[FILTER_CONSTANTS.RETAILER]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	[FILTER_CONSTANTS.STOCK]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: ['In Stock', 'Out of stock'],
	},
	[FILTER_CONSTANTS.RESELLER]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
	[FILTER_CONSTANTS.BRAND]: {
		isInitialState: true,
		isLoading: false,
		isAllLoaded: false,
		suggestions: [],
	},
}

const filterSuggestions = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.GET_SUGGESTIONS_PENDING: {
			return {
				...state,
				[action.filterKey]: {
					isInitialState: false,
					isLoading: true,
					suggestions: [...state[action.filterKey].suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_SUGGESTIONS_FULFILLED: {
			const productFilters = [
				FILTER_CONSTANTS.RETAILER,
				FILTER_CONSTANTS.PRODUCT_NAME,
				FILTER_CONSTANTS.BRAND,
				FILTER_CONSTANTS.STOCK,
				FILTER_CONSTANTS.RESELLER,
				FILTER_CONSTANTS.DISCOUNT,
			]
			const newSuggestions = [...(action.isNewData ? [] : state[action.filterKey].suggestions), ...action.data.data.items]

			if (_.includes(productFilters, action.filterKey)) {
				return {
					...state,
					[action.filterKey]: {
						isInitialState: false,
						isLoading: false,
						suggestions: newSuggestions,
						isAllLoaded: newSuggestions.length === action.data.data.total_count || action.data.data.items.length === action.data.data.total_count,
					},
				}
			} else {
				return {
					...state,
					[action.filterKey]: {
						isInitialState: false,
						isLoading: false,
						suggestions: newSuggestions,
						isAllLoaded: newSuggestions.length === action.data.data.total_size || action.data.data.items.length === action.data.data.total_size,
					},
				}
			}
		}

		case ACTION_TYPES.GET_SUGGESTIONS_REJECTED: {
			return {
				...state,
				[action.filterKey]: {
					isLoading: false,
					isInitialState: false,
					suggestions: [...state[action.filterKey].suggestions],
					// isAllLoaded: state[action.filterKey].suggestions.length === action.payload.total_size || action.payload.items === action.payload.total_size
				},
			}
		}

		case ACTION_TYPES.UPDATE_JOB_FILTER_DATA: {
			return {
				...state,
				[FILTER_CONSTANTS.JOB_STATUS]: {
					isLoading: false,
					isInitialState: false,
					suggestions: action.data,
				},
			}
		}

		case ACTION_TYPES.GET_STYLE_CODE_SUGGESTIONS_PENDING: {
			return {
				...state,
				styleCode: {
					isLoading: true,
					isInitialState: false,
					suggestions: [...state.styleCode.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_STYLE_CODE_SUGGESTIONS_FULFILLED: {
			return {
				...state,
				styleCode: {
					isLoading: false,
					isInitialState: false,
					suggestions: _.uniqBy([...state.styleCode.suggestions, ...action.payload.search_result], _.get(action, 'key', 'objectID')),
					isAllLoaded:
						state.styleCode.suggestions.length === action.payload.total_count || action.payload.search_result.length === action.payload.total_count,
				},
			}
		}

		case ACTION_TYPES.GET_STYLE_CODE_SUGGESTIONS_REJECTED: {
			return {
				...state,
				styleCode: {
					isLoading: false,
					isInitialState: false,
					suggestions: [...state.styleCode.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_RETAILER_SUGGESTIONS_PENDING: {
			return {
				...state,
				retailer: {
					isLoading: true,
					isInitialState: false,
					suggestions: [...state.retailer.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_RETAILER_SUGGESTIONS_FULFILLED: {
			return {
				...state,
				retailer: {
					isLoading: false,
					isInitialState: false,
					isAllLoaded: state.retailer.suggestions.length === action.payload.total_count || action.payload.length === action.payload.total_count,
					suggestions: [...state.retailer.suggestions, ...action.payload.items],
				},
			}
		}

		case ACTION_TYPES.GET_RETAILER_SUGGESTIONS_REJECTED: {
			return {
				...state,
				retailer: {
					isLoading: false,
					isInitialState: false,
					suggestions: [...state.retailer.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_PENDING: {
			return {
				...state,
				product: {
					isLoading: true,
					isInitialState: false,
					suggestions: [...state.product.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_FULFILLED: {
			return {
				...state,
				product: {
					isLoading: false,
					isInitialState: false,
					isAllLoaded: state.retailer.suggestions.length === action.payload.total_count || action.payload.length === action.payload.total_count,
					suggestions: [...state.product.suggestions, ...action.payload.items],
				},
			}
		}

		case ACTION_TYPES.GET_PRODUCT_SUGGESSIONS_REJECTED: {
			return {
				...state,
				product: {
					isLoading: false,
					isInitialState: false,
					suggestions: [...state.product.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_RESELLER_SUGGESSIONS_PENDING: {
			return {
				...state,
				reseller: {
					isLoading: true,
					isInitialState: false,
					suggestions: [...state.product.suggestions],
				},
			}
		}

		case ACTION_TYPES.GET_RESELLER_SUGGESSIONS_FULFILLED: {
			return {
				...state,
				reseller: {
					isLoading: false,
					isInitialState: false,
					isAllLoaded: state.retailer.suggestions.length === action.payload.total_count || action.payload.length === action.payload.total_count,
					suggestions: [...state.reseller.suggestions, ...action.payload.items],
				},
			}
		}

		case ACTION_TYPES.GET_RESELLER_SUGGESSIONS_REJECTED: {
			return {
				...state,
				reseller: {
					isLoading: false,
					isInitialState: false,
					suggestions: [...state.reseller.suggestions],
				},
			}
		}

		case ACTION_TYPES.CLEAR_SUGGESTIONS: {
			return { ...initialState }
		}

		default: {
			return state
		}
	}
}

export default filterSuggestions
