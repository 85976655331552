import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'

/* NEW CODE START */

export const fetchGlassDetails = (glassCode) => ({
	type: SAGA_ACTIONS.FETCH_GLASS_DETAILS,
	glassCode,
})

export const updateGlassMember = (sessionId, glassCode, userId, role, updateRedux = false, successCallback = null, errCallback = null) => ({
	type: SAGA_ACTIONS.UPDATE_GLASS_MEMBER,
	sessionId,
	glassCode,
	userId,
	role,
	updateRedux,
	successCallback,
	errCallback,
})

export const removeGlassMember = (sessionId, glassCode, userId, updateRedux = false, successCallback = null, errCallback = null) => ({
	type: SAGA_ACTIONS.REMOVE_GLASS_MEMBER,
	sessionId,
	glassCode,
	userId,
	updateRedux,
	successCallback,
	errCallback,
})

export const addGlassMembers = (glassMemArry) => ({
	type: ACTION_TYPES.ADD_GLASS_MEMBERS,
	payload: glassMemArry,
})

export const addGlassLabel = (sessionId, glassCode, labelName, labelColor, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.ADD_GLASS_LABEL,
	sessionId,
	glassCode,
	labelName,
	labelColor,
	successCallback,
	errCallback,
})

export const updateGlassLabel = (sessionId, glassCode, labelId, labelName, labelColor, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.UPDATE_GLASS_LABEL,
	sessionId,
	glassCode,
	labelId,
	labelName,
	labelColor,
	successCallback,
	errCallback,
})

export const removeGlassLabel = (sessionId, glassCode, labelId, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.REMOVE_GLASS_LABEL,
	sessionId,
	glassCode,
	labelId,
	successCallback,
	errCallback,
})

export const addGlassActType = (sessionId, glassCode, activityName, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.ADD_GLASS_ACTIVITY_TYPE,
	sessionId,
	glassCode,
	activityName,
	successCallback,
	errCallback,
})

export const updateGlassActType = (sessionId, glassCode, actTypeId, activityName, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.UPDATE_GLASS_ACTIVITY_TYPE,
	sessionId,
	glassCode,
	actTypeId,
	activityName,
	successCallback,
	errCallback,
})

export const removeGlassActType = (sessionId, glassCode, actTypeId, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.REMOVE_GLASS_ACTIVITY_TYPE,
	sessionId,
	glassCode,
	actTypeId,
	successCallback,
	errCallback,
})

export const updateGlassStickyEsc = (sessionId, glassCode, stickyEscVal, stickyReminderVal, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.UPDATE_GLASS_STICKY_ESC,
	sessionId,
	glassCode,
	stickyEscVal,
	stickyReminderVal,
	successCallback,
	errCallback,
})

export const addGlassPane = (sessionId, glassCode, paneName, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.ADD_GLASS_PANE,
	sessionId,
	glassCode,
	paneName,
	successCallback,
	errCallback,
})
export const removeGlassPane = (sessionId, glassCode, paneId, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.REMOVE_GLASS_PANE,
	sessionId,
	glassCode,
	paneId,
	successCallback,
	errCallback,
})
export const updateGlassPane = (sessionId, glassCode, paneId, paneName, panePosition, panePrevPosition, isEdit, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.UPDATE_GLASS_PANE,
	sessionId,
	glassCode,
	paneId,
	paneName,
	panePosition,
	panePrevPosition,
	isEdit,
	successCallback,
	errCallback,
})

export const updateGlassPanePosition = (paneId, pane) => ({
	type: ACTION_TYPES.UPDATE_GLASS_PANE,
	payload: { paneId, pane },
})

export const createSticky = (glassCode, data, sessionId, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.ADD_STICKY,
	glassCode,
	sessionId,
	payload: data,
	successCallback,
	errCallback,
})

export const moveSticky = (sessionId, glassCode, sticky, toPaneId, prevStickyCode, successCallback, errCallback) => ({
	type: SAGA_ACTIONS.MOVE_STICKY,
	sessionId,
	glassCode,
	sticky,
	toPaneId,
	prevStickyCode,
	successCallback,
	errCallback,
})

export const addStickyData = (data) => ({
	type: ACTION_TYPES.ADD_GLASS_STICKY,
	payload: data,
})

export const updateStickyData = (data) => ({
	type: ACTION_TYPES.UPDATE_GLASS_STICKY,
	payload: data,
})

export const updateGlassLayout = (layout) => ({
	type: ACTION_TYPES.UPDATE_GLASS_LAYOUT,
	payload: { layout },
})

export const removeSticky = (sessionId, glassCode, stickyCode, successCallback, errCallback) => {
	return {
		type: SAGA_ACTIONS.REMOVE_STICKY,
		sessionId,
		glassCode,
		stickyCode,
		successCallback,
		errCallback,
	}
}

export const UntagUser = (sessionId, glassCode, stickyCode, user_id, successCallback, errCallback) => {
	return {
		type: SAGA_ACTIONS.UNTAG_USER,
		sessionId,
		glassCode,
		stickyCode,
		successCallback,
		errCallback,
		user_id,
	}
}

export const resetGlass = () => ({
	type: ACTION_TYPES.RESET_GLASS,
})

export const updateGlassSticky = (payload) => ({
	type: ACTION_TYPES.UPDATE_GLASS_STICKY,
	payload,
})

export const setGlassFilter = (glassCode, sessionId, payload, searchText, errCallback) => ({
	type: SAGA_ACTIONS.SAGA_SET_GLASS_FILTER,
	glassCode,
	sessionId,
	payload,
	searchText,
	errCallback,
})

export const getGlassFilter = (glassCode, sessionId) => ({
	type: SAGA_ACTIONS.SAGA_GET_GLASS_FILTER,
	glassCode,
	sessionId,
})

export const getGlassFilterSuccess = (payload) => ({
	type: ACTION_TYPES.GET_GLASS_FILTERS_SUCCESS,
	payload,
})

export const updateStickList = (stickyList) => ({
	type: ACTION_TYPES.UPDATE_STICKY_LIST,
	payload: { stickyList },
})

export const refreshStickyList = (glassCode, searchText, errCallback) => ({
	type: SAGA_ACTIONS.REFRESH_STICKY_LIST,
	glassCode,
	searchText,
	errCallback,
})

/* NEW CODE END */

export const fetchGlassConfig = (glassId = '', cancelExecutor) => ({
	type: SAGA_ACTIONS.FETCH_GLASS_CONFIG,
	glassId,
	cancelExecutor,
})

export const fetchGlassLanes = (glassId = '', cancelExecutor) => ({
	type: SAGA_ACTIONS.FETCH_GLASS_LANES,
	glassId,
	cancelExecutor,
})

export const updateGlassConfig = (key, data, matchBy = 'id', updateType = 'set') => ({
	type: ACTION_TYPES.UPDATE_GLASS_CONFIG,
	key,
	payload: data,
	updateType,
	matchBy,
})

export const updateGlassDetails = (data) => ({
	type: ACTION_TYPES.FETCH_GLASS_DETAILS_FULFILLED,
	payload: data,
})

export const fetchGlassAssignees = (glassId = '', data = { search_text: '', offset: 0, limit: 100 }, cancelExecutor) => ({
	type: SAGA_ACTIONS.FETCH_GLASS_ASSIGNEES,
	glassId,
	data,
	cancelExecutor,
})

export const updateGlassAssignees = (data = {}) => ({
	type: ACTION_TYPES.UPDATE_GLASS_ASSIGNEES,
	payload: data,
})

export const addNewLane = (newlaneData) => ({
	type: ACTION_TYPES.ADD_NEW_LANE,
	payload: newlaneData,
})

export const updateLanes = (changedData) => ({
	type: ACTION_TYPES.UPDATE_LANES,
	payload: changedData,
})

export const updateGlassLayoutConfig = (data) => ({
	type: ACTION_TYPES.UPDATE_GLASS_LAYOUT_CONFIG,
	data,
})

export const updateGlassDetailsAfterEdit = (payload) => ({
	type: ACTION_TYPES.UPDATE_GLASS_DETAILS_AFTER_EDIT,
	payload,
})
