import { Icon, IconButton, makeStyles, Grid, Button } from '@material-ui/core'
import React, { useRef } from 'react'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'
import { getErrMsg } from 'utils'
import Axios from 'axios'
import DiscoverApi from '../../../../services/discoverx/api'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	headerRoot: {
		padding: theme.spacing(1.5, 2, 0),
		'& .MuiFormControl-fullWidth': {
			marginTop: 0,
		},
		'& .MuiOutlinedInput-inputMarginDense': {
			padding: theme.spacing(1),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1.2, 2, 0, 2),
		},
	},
	headerPosition: {
		position: 'sticky',
		top: 0,
		zIndex: 999,
		backgroundColor: 'white',
	},
	closeIcon: {
		textAlign: 'center',
	},

	headerIconSec: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& button': {
			// marginLeft: theme.spacing(1),
		},
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'flex-end',
		},
	},
	docType: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		padding: '2px 6px',
		fontWeight: '500',
		borderRadius: '4px',
		marginLeft: theme.spacing(0.5),
		fontSize: '12px',
	},
	stickyBtn: {
		// marginLeft: theme.spacing(2),
	},
	stickyIco: {
		marginRight: theme.spacing(1),
		verticalAlign: 'middle',
	},
	isFavorite: {
		color: theme.palette.error.main,
	},
}))

const Header = ({ data, onClose, onStickyClick }) => {
	const classes = useStyles()
	const [isFavorite, setIsFavorite] = React.useState(data?.is_wishlist)
	const dispatch = useDispatch()
	const cancelExecutor = useRef()

	const toggleFavorite = () => {
		setIsFavorite(!isFavorite)
	}

	const onFavoriteChange = (e) => {
		const prevState = isFavorite
		toggleFavorite()
		const formData = { is_wishlist: !isFavorite }

		const onFailure = (err) => {
			if (!Axios.isCancel(err)) {
				setIsFavorite(prevState)
				dispatch(showSnackbarWithTimeout(getErrMsg(err), 1500))
			}
		}
		DiscoverApi.onChangeLabel(data?.ds_id, data?.id, formData, cancelExecutor).then(null, onFailure)
	}
	return (
		<div className={classes.headerPosition}>
			<Grid container justifyContent='space-between' alignItems='center' className={classes.headerRoot}>
				<Grid item lg={8} sm={6} xs={3}>
					<React.Fragment>
						<div className={classes.titleSec}>
							<span className={classes.docType}>{data?.doc_type}</span>
						</div>
					</React.Fragment>
				</Grid>
				<Grid item lg={4} sm={6} xs={9} className={classes.closeIcon}>
					<div className={classes.headerIconSec}>
						<IconButton onClick={onFavoriteChange}>
							<Icon className={isFavorite ? classes.isFavorite : {}}>{isFavorite ? 'favorite' : 'favorite_border'}</Icon>
						</IconButton>
						<Button
							size='small'
							color='primary'
							className={classes.stickyBtn}
							variant='contained'
							disableElevation
							onClick={() => onStickyClick(data)}
						>
							<Icon fontSize='small' className={classes.stickyIco}>
								sticky_note_2
							</Icon>
							PipeLine
						</Button>
						<IconButton size='small' edge='end' onClick={onClose}>
							<Icon>close</Icon>
						</IconButton>
					</div>
				</Grid>
			</Grid>
		</div>
	)
}

export default Header
