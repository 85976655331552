import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
import _ from 'lodash'
import { convertLocalTimeToGmtStr, jsonToFormData } from 'utils'
const CancelToken = Axios.CancelToken

const getUTC = (t) => (_.isUndefined(t) ? '' : _.isNull(t) ? null : convertLocalTimeToGmtStr(t))

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	/* NEW CODE START */
	fetchGlassDetails: (glassCode, cancelSource) => {
		if (cancelSource.current) {
			cancelSource.current()
		}
		return Axios.get(API_ENDPOINTS.GLASS_DETAILS, {
			cancelToken: new CancelToken(function executor(c) {
				cancelSource.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassCode },
			},
		})
	},
	updateGlassMember: (sessionId, glassCode, userId, role, cancelSource) => {
		return Axios.put(
			API_ENDPOINTS.GLASS_MEMBERS,
			{ user_id: [userId], role },
			{
				cancelToken: cancelSource.token || cancelSource,
				headers: {
					'Content-Type': 'application/json',
					sid: sessionId,
					isAuthRequired: true,
					path: { glassCode },
				},
			}
		)
	},
	removeGlassMember: (sessionId, glassCode, userId, cancelSource) => {
		return Axios.delete(API_ENDPOINTS.GLASS_MEMBERS, {
			cancelToken: cancelSource.token || cancelSource,
			data: { user_id: [userId] },
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				sid: sessionId,
				path: { glassCode },
			},
		})
	},
	fetchAllGlasses: (search, offset, limit, projectCode, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		Axios.get(`${API_ENDPOINTS.FETCH_GLASSES}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { search, offset, limit, projectCode },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	createGlass: (formData, sessionId) => {
		return Axios.post(
			`${API_ENDPOINTS.CREATE_GLASS}`,
			{ ...formData },
			{ headers: { 'Content-Type': 'application/json', sid: sessionId, isAuthRequired: true } }
		)
	},
	updateGlass: (data, glassCode, sessionId) => {
		return Axios.put(
			`${API_ENDPOINTS.GLASS_DETAILS}`,
			{ ...data },
			{
				headers: {
					'Content-Type': 'application/json',
					isAuthRequired: true,
					sid: sessionId,
					path: { glassCode },
				},
			}
		)
	},
	deleteGlass: (glassCode) => {
		return Axios.delete(`${API_ENDPOINTS.GLASS_DETAILS}`, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassCode },
			},
		})
	},
	addGlassMembers: (sessionId, glassCode, user_ids) => {
		return Axios.post(
			API_ENDPOINTS.GLASS_MEMBERS,
			{ user_id: [...user_ids] },
			{ headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { glassCode } } }
		)
	},
	fetchGlassLogs: (glassCode, limit, offset, sort_by) => {
		return Axios.post(
			API_ENDPOINTS.GLASS_LOGS,
			{ limit, offset, sort_by },
			{ headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { glassCode } } }
		)
	},
	fetchAddMembersList: (glassCode, search_text) => {
		return Axios.get(API_ENDPOINTS.GLASS_ADD_MEMBERS_LIST, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { glassCode } },
			params: { search_text, offset: 0, limit: 5 },
		})
	},
	getGlassMembersList: (glassCode) => {
		return Axios.get(API_ENDPOINTS.GLASS_MEMBERS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { glassCode } },
		})
	},
	createLabel: (glassCode, labelName, labelColor, sessionId) => {
		return Axios.post(
			API_ENDPOINTS.GLASS_LABELS,
			{
				label_name: labelName,
				label_color: labelColor,
			},
			{
				headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { glassCode } },
			}
		)
	},
	updateLabel: (glassCode, labelId, labelName, labelColor, sessionId) => {
		return Axios.put(
			API_ENDPOINTS.GLASS_LABELS,
			{
				label_id: labelId,
				label_name: labelName,
				label_color: labelColor,
			},
			{
				headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { glassCode } },
			}
		)
	},
	deleteLabel: (glassCode, labelId, sessionId) => {
		return Axios.delete(API_ENDPOINTS.GLASS_LABELS, {
			data: { label_id: labelId },
			headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { glassCode } },
		})
	},
	createActivityType: (glassCode, activityName, sessionId) => {
		return Axios.post(
			API_ENDPOINTS.GLASS_ACTIVITY_TYPES,
			{
				activity_name: activityName,
			},
			{
				headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { glassCode } },
			}
		)
	},
	updateActivityType: (glassCode, actTypeId, activityName, sessionId) => {
		return Axios.put(
			API_ENDPOINTS.GLASS_ACTIVITY_TYPES,
			{
				activity_id: actTypeId,
				activity_name: activityName,
			},
			{
				headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { glassCode } },
			}
		)
	},
	deleteActivityType: (glassCode, actTypeId, sessionId) => {
		return Axios.delete(API_ENDPOINTS.GLASS_ACTIVITY_TYPES, {
			data: { activity_id: actTypeId },
			headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { glassCode } },
		})
	},
	updateStickyEsc: (glassCode, stickyEscValue, stickyReminderVal, sessionId) => {
		return Axios.put(
			API_ENDPOINTS.GLASS_STICKY_ESC,
			{
				sticky_escalation: stickyEscValue,
				sticky_reminder: stickyReminderVal,
			},
			{
				headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { glassCode } },
			}
		)
	},
	updateGlassLayout: (glassCode, layout, sessionId) => {
		return Axios.put(
			API_ENDPOINTS.GLASS_LAYOUT_UPDATE,
			{
				layout: layout,
			},
			{
				headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { glassCode } },
			}
		)
	},

	createPane: (glassCode, paneName, sessionId) => {
		return Axios.post(
			API_ENDPOINTS.PANE_API,
			{
				pane_name: paneName,
			},
			{
				headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { glassCode } },
			}
		)
	},

	glassPaneRemove: (glassCode, paneId, sessionId) => {
		return Axios.delete(API_ENDPOINTS.PANE_API, {
			data: {
				pane_id: paneId,
			},
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				sid: sessionId,
				path: { glassCode },
			},
		})
	},

	glassPaneUpdate: (glassCode, paneId, paneName, panePosition, sessionId) => {
		return Axios.put(
			API_ENDPOINTS.PANE_API,
			{
				pane_id: paneId,
				pane_name: paneName,
				pane_position: panePosition,
			},
			{
				headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { glassCode } },
			}
		)
	},

	createSticky: (glassCode, data, sessionId) => {
		const formData = jsonToFormData(data, 'attachments')
		return Axios.post(API_ENDPOINTS.STICKY_API, formData, {
			headers: { isAuthRequired: true, path: { glassCode }, sid: sessionId, 'Content-Type': 'multipart/form-data' },
		})
	},

	fetchStickyList: (glassCode, searchText = '', cancelSource) => {
		return Axios.post(
			API_ENDPOINTS.STICKY_LIST_API,
			{ search_text: searchText },
			{ headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { glassCode } }, cancelToken: cancelSource.token }
		)
	},

	moveSticky: (sessionId, glassCode, stickyCode, paneId, prevStickyCode) => {
		return Axios.put(
			API_ENDPOINTS.STICKY_MOVE_API,
			{
				pane_id: paneId,
				prev_sticky_code: prevStickyCode,
			},
			{ headers: { isAuthRequired: true, sid: sessionId, 'Content-Type': 'application/json', path: { glassCode, stickyCode } } }
		)
	},
	updateTask: (glassCode, stickyCode, sessionId, data) => {
		return Axios.post(`${API_ENDPOINTS.STICKY_TASK_UPDATE}`, data, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassCode, stickyCode },
				sid: sessionId,
			},
		})
	},
	deleteSticky: (glassCode, stickyCode, sessionId) => {
		return Axios.delete(`${API_ENDPOINTS.STICK_DELETE}`, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassCode, stickyCode },
				sid: sessionId,
			},
		})
	},
	untagUser: (data, glassCode, stickyCode, sessionId) => {
		return Axios.put(`${API_ENDPOINTS.UNTAG_USER}`, data, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassCode, stickyCode },
				sid: sessionId,
			},
		})
	},
	cloneSticky: (glassCode, stickyCode, values, sessionId) => {
		return Axios.post(
			API_ENDPOINTS.GLASS_STICKY_CLONE,
			{ ...values },
			{ headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { glassCode, stickyCode }, sid: sessionId } }
		)
	},

	cutSticky: (glassCode, stickyCode, values, sessionId) => {
		return Axios.post(
			API_ENDPOINTS.GLASS_STICKY_CUT,
			{ ...values },
			{ headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { glassCode, stickyCode }, sid: sessionId } }
		)
	},

	startStickyTimer: (glassCode, stickyCode, from, sessionId) => {
		return Axios.post(
			API_ENDPOINTS.STICKY_LOG_TIMER_START,
			{ from },
			{ headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { glassCode, stickyCode }, sid: sessionId } }
		)
	},
	stopStickyTimer: (glassCode, stickyCode, to, sessionId) => {
		return Axios.post(
			API_ENDPOINTS.STICKY_LOG_TIMER_STOP,
			{ to },
			{ headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { glassCode, stickyCode }, sid: sessionId } }
		)
	},
	addStickyTimeLog: (glassCode, stickyCode, bodyData, sessionId) => {
		return Axios.post(API_ENDPOINTS.STICKY_LOG_TIMER_MANUAL, bodyData, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { glassCode, stickyCode }, sid: sessionId },
		})
	},
	updateStickyTimeLog: (glassCode, stickyCode, timeLogId, bodyData, sessionId) => {
		return Axios.put(API_ENDPOINTS.STICKY_LOG_TIMER_UPDATE, bodyData, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { glassCode, stickyCode, timeLogId }, sid: sessionId },
		})
	},
	deleteStickyTimeLog: (glassCode, stickyCode, timeLogId, sessionId) => {
		return Axios.delete(API_ENDPOINTS.STICKY_LOG_TIMER_UPDATE, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { glassCode, stickyCode, timeLogId }, sid: sessionId },
		})
	},
	clearAllStickyTimeLogs: (glassCode, stickyCode, sessionId) => {
		return Axios.delete(API_ENDPOINTS.STICKY_LOG_TIMER_CLEAR, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { glassCode, stickyCode }, sid: sessionId },
		})
	},
	fetchGlassMembers: (glassCode, data, cancelSource) => {
		return Axios.get(API_ENDPOINTS.GLASS_MEMBERS, {
			cancelToken: cancelSource.token || cancelSource,
			params: data,
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassCode },
			},
		})
	},
	fetchGlassLabels: (glassCode, cancelExecutor) => {
		if (cancelExecutor.current) {
			cancelExecutor.current()
		}
		return Axios.get(`${API_ENDPOINTS.GLASS_LABELS}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassCode },
			},
		})
	},
	/* NEW CODE END */
	fetchGlassConfig: (glassId, cancelSource) => {
		return Axios.get(`${API_ENDPOINTS.GLASS_CONFIG}`, {
			cancelToken: cancelSource.token,
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassId },
			},
		})
	},
	fetchProjectsList: (search_text, offset, limit, searchFields, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) cancelExecutor.current()
		Axios.get(`${API_ENDPOINTS.FETCH_PROJECTS_LIST}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			params: { search_text, offset, limit, searchFields },
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { search_text, offset, limit, searchFields },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchAssignees: (glassId, data, cancelSource) => {
		return Axios.post(API_ENDPOINTS.GLASS_MEMBERS, data, {
			cancelToken: cancelSource.token || cancelSource,
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassId },
			},
		})
	},
	createGlassConfig: (glassId, configType, formData, cancelSource) => {
		return Axios.post(`${API_ENDPOINTS.CREATE_GLASS_CONFIG}`, formData, {
			cancelToken: cancelSource?.token || cancelSource,
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassId, configType },
			},
		})
	},
	updateGlassLayoutConfig: (glassId, formData, cancelSource) => {
		return Axios.post(`${API_ENDPOINTS.GLASS_CONFIG}`, formData, {
			cancelToken: cancelSource?.token || cancelSource,
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassId },
			},
		})
	},
	updateGlassConfig: (glassId, configType, configId, formData) => {
		return Axios.put(`${API_ENDPOINTS.UPDATE_GLASS_CONFIG}`, formData, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassId, configType, configId },
			},
		})
	},
	updateCardPosition: (glassId, data, filters) => {
		let dueDateObj = _.isEmpty(_.get(filters, 'due_date.key'))
			? {
					from_due_date: _.isUndefined(filters?.due_date?.from) ? '' : getUTC(filters?.due_date?.from),
					to_due_date: _.isUndefined(filters?.due_date?.to) ? '' : getUTC(filters?.due_date?.to),
			  }
			: {
					from_due_date: convertLocalTimeToGmtStr(_.get(filters, 'due_date.startDate')),
					to_due_date: convertLocalTimeToGmtStr(_.get(filters, 'due_date.endDate')),
			  }
		let tempBody = {
			filters: [
				{
					assignee: filters?.assignee || [],
					...dueDateObj,
					label_ids: filters?.label || [],
					priority: filters?.priority || [],
					lane_ids: [],
				},
			],
		}
		return Axios.put(
			API_ENDPOINTS.UPDATE_CARD_POSITION,
			{ ...data, ...tempBody },
			{
				headers: {
					'Content-Type': 'application/json',
					isAuthRequired: true,
					path: { glassId },
				},
			}
		)
	},
	deleteGlassConfig: (glassId, configType, configId) => {
		return Axios.delete(`${API_ENDPOINTS.UPDATE_GLASS_CONFIG}`, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassId, configType, configId },
			},
		})
	},
	fetchGlassData: (glassId, filters, cancelSource) => {
		let dueDateObj = _.isEmpty(_.get(filters, 'due_date.key'))
			? {
					from_due_date: _.isUndefined(filters?.due_date?.from) ? '' : getUTC(filters?.due_date?.from),
					to_due_date: _.isUndefined(filters?.due_date?.to) ? '' : getUTC(filters?.due_date?.to),
			  }
			: {
					from_due_date: convertLocalTimeToGmtStr(_.get(filters, 'due_date.startDate')),
					to_due_date: convertLocalTimeToGmtStr(_.get(filters, 'due_date.endDate')),
			  }
		let tempBody = {
			filters: [
				{
					assignee: filters?.assignee || [],
					...dueDateObj,
					label_ids: filters?.label || [],
					priority: filters?.priority || [],
					lane_ids: [],
				},
			],
		}
		return Axios.post(API_ENDPOINTS.ALL_CARDS_OF_GLASS, tempBody, {
			cancelToken: cancelSource?.token || cancelSource,
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassId },
			},
		})
	},
	createCard: (glassId, formData) => {
		return Axios.post(API_ENDPOINTS.CREATE_CARD, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				isAuthRequired: true,
				path: { glassId },
			},
		})
	},
	updateLanePosition: (glassId, data) => {
		return Axios.put(API_ENDPOINTS.UPDATE_LANE_POSITION, data, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassId },
			},
		})
	},
	getStickyDetails: (glassCode, stickyCode, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GLASS_STICKY_DETAILS, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassCode, stickyCode },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	updateStickyDetails: (glassCode, stickyCode, sessionId, formData, cancelToken) => {
		return Axios.put(API_ENDPOINTS.GLASS_STICKY_DETAILS, formData, {
			cancelToken: new CancelToken(function executor(c) {
				cancelToken.current = c
			}),
			headers: {
				'Content-Type': 'multipart/form-data',
				isAuthRequired: true,
				path: { glassCode, stickyCode },
				sid: sessionId,
			},
		})
	},

	uploadAttachment: (glassCode, stickyCode, formData, sessionId) => {
		return Axios.post(API_ENDPOINTS.GLASS_STICKY_ATTACHMENT, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				isAuthRequired: true,
				path: { glassCode, stickyCode },
				sid: sessionId,
			},
		})
	},

	export: (data, glassCode) => {
		return Axios.post(API_ENDPOINTS.GLASSX_EXPORT, data, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { glassCode } },
			responseType: 'blob',
		})
	},

	deleteAttachment: (glassCode, stickyCode, attachmentId, sessionId) => {
		return Axios.delete(API_ENDPOINTS.GLASS_STICKY_ATTACHMENT, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassCode, stickyCode },
			},
			data: { attachment_ids: [attachmentId] },
			sid: sessionId,
		})
	},

	getStickyActivities: (sort_by, offset, limit, is_pinned, glassCode, stickyCode, excludeSysLog, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GLASS_STICKY_ACTIVITIES, {
			params: { sort_by, offset, limit, is_pinned, is_log: excludeSysLog ? !excludeSysLog : null },
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { glassCode, stickyCode },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	postStickyActivities: (glassCode, stickyCode, sessionId, formData) => {
		return Axios.post(API_ENDPOINTS.GLASS_STICKY_ACTIVITIES, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				isAuthRequired: true,
				path: { glassCode, stickyCode },
				sid: sessionId,
			},
		})
	},

	updateStickyActivities: (glassCode, stickyCode, activityId, sessionId, formData) => {
		return Axios.put(API_ENDPOINTS.UPDATE_GLASS_STICKY_ACTIVITIES, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				isAuthRequired: true,
				path: { glassCode, stickyCode, activityId },
				sid: sessionId,
			},
		})
	},
	setFilters: (glassCode, sessionId, data, cancelSource) => {
		return Axios.post(
			API_ENDPOINTS.GLASS_FILTERS,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', sid: sessionId, path: { glassCode }, isAuthRequired: true }, cancelToken: cancelSource.token }
		)
	},

	getFilters: (glassCode, sessionId) => {
		return Axios.get(API_ENDPOINTS.GLASS_FILTERS, {
			headers: { 'Content-Type': 'application/json', sid: sessionId, path: { glassCode }, isAuthRequired: true },
		})
	},

	getUnlinedStickies: (glassCode, stickyCode, data) => {
		return Axios.post(
			API_ENDPOINTS.GET_UNLINKED_STICKIES,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', path: { glassCode, stickyCode }, isAuthRequired: true } }
		)
	},

	setUnlinkedStickies: (glassCode, stickyCode, data, sessionId) => {
		return Axios.post(
			API_ENDPOINTS.LINK_STICKIES,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', path: { glassCode, stickyCode }, isAuthRequired: true, sid: sessionId } }
		)
	},
	createNewSubTask: (glassCode, stickyCode, data, sessionId) => {
		return Axios.post(API_ENDPOINTS.SUBTASK, data, {
			headers: { 'Content-Type': 'multipart/form-data', path: { glassCode, stickyCode }, isAuthRequired: true, sid: sessionId },
		})
	},
	getSubTasks: (glassCode, stickyCode) => {
		return Axios.get(API_ENDPOINTS.SUBTASK, {
			headers: { 'Content-Type': 'application/json', path: { glassCode, stickyCode }, isAuthRequired: true },
		})
	},
	deleteSubTask: (glassCode, stickyCode, data, sessionId) => {
		return Axios.post(API_ENDPOINTS.UNLINK_STICKIES, data, {
			headers: { 'Content-Type': 'application/json', path: { glassCode, stickyCode }, isAuthRequired: true, sid: sessionId },
		})
	},
	updateCustomDashboard: (glassCode, dashboard_config, dashboard_type, dashboard_id) => {
		return Axios.put(
			API_ENDPOINTS.GLASS_CUSTOM_DASHBOARD,
			{
				dashboard_config: dashboard_config,
				dashboard_type: dashboard_type,
				dashboard_id: dashboard_id,
			},
			{
				headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { glassCode } },
			}
		)
	},
}
