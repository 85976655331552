import React from 'react'
import classnames from 'classnames'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import DiscoverXSvgImg from 'images/discover-x-svg.svg'
import DiscoverXImg from 'images/discover-x-fallback.png'
import DisXInpBox from 'components/DisXInpBox'

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
		boxSizing: 'border-box',
		padding: theme.spacing(2),
	},
	searchWrapper: {
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	searchContent: {
		textAlign: 'center',
	},
	searchBox: {
		maxWidth: '480px',
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
		[theme.breakpoints.down('xs')]: {
			maxWidth: '320px',
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(4),
		},
	},
	discoverXLogo: {
		[theme.breakpoints.down('xs')]: {
			maxWidth: '166px',
		},
		[theme.breakpoints.up('sm')]: {
			maxWidth: '240px',
		},
		[theme.breakpoints.up('md')]: {
			maxWidth: '260px',
		},
	},
}))

const DiscoverX = ({ history }) => {
	const classes = useStyles()

	const onChangeSearch = (e, value) => {
		if (value) {
			history.push(`/discoverx/search?q=${value}`)
		}
	}

	return (
		<div className={classnames('container-no-fluid', 'page-content', classes.root)}>
			<Grid container className={classes.searchWrapper}>
				<Grid item xs={12} className={classes.searchContent}>
					<object data={DiscoverXSvgImg} className={classes.discoverXLogo} type='image/svg+xml'>
						<img src={DiscoverXImg} className={classes.discoverXLogo} alt='img' />
					</object>
					<DisXInpBox onChange={onChangeSearch} inputClassName={classes.searchBox} />
					<Typography variant='caption' color='textSecondary'>
						Real business runs on Data, Baachu runs on Data
					</Typography>
				</Grid>
			</Grid>
		</div>
	)
}

DiscoverX.propTypes = {}

export default DiscoverX
