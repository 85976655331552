import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Icon, makeStyles, TextField, Typography, Link, Breadcrumbs } from '@material-ui/core'
import PopoverMenus from 'components/PopoverMenus'
import { PERMISSION_TYPE } from 'constants/modules'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { getErrMsg, getSuccessMsg } from 'utils'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from 'services/loader/actions'
import { removeSticky, refreshStickyList } from 'services/glass/actions'
import CloneForm from './CloneForm'
import MoveForm from './MoveForm'
import { showSnackbarWithTimeout } from 'services/snackbar/actions'

import { useDispatch } from 'react-redux'
const useStyles = makeStyles((theme) => ({
	headerRoot: {
		'& .MuiFormControl-fullWidth': {
			marginTop: 0,
		},
		'& .MuiOutlinedInput-inputMarginDense': {
			padding: theme.spacing(1),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1.2, 2, 0, 2),
		},
		padding: theme.spacing(1, '20px'),
	},
	closeIcon: {
		textAlign: 'center',
	},
	titleSec: {
		overflow: 'hidden',
		cursor: 'text',
		'& h5': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	subTitleSec: {
		overflow: 'hidden',
		'& .MuiTypography-caption': {
			marginTop: theme.spacing(0.5),
			marginBottom: theme.spacing(1),
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(0),
				marginTop: theme.spacing(0),
			},
		},
	},
	headerIconSec: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& button': {
			margin: theme.spacing(0, 1),
		},
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'flex-end',
		},
	},
	parentSticky: {
		fontSize: '13px',
		cursor: 'pointer',
	},
}))

const Header = ({
	onClose,
	formik,
	isEditEnabled,
	isOwnerOrCreatedBy,
	isOwnerOrCreatedByOrReporter,
	stickyDetails,
	onHeaderClick,
	sessionId,
	glassCode,
	stickyCode,
	glassMembers,
	lanesData,
	history,
}) => {
	const classes = useStyles()
	const [titleClicked, setTitleClicked] = useState(false)
	const [error, setError] = useState({
		isError: false,
		errorText: '',
	})
	const { isError, errorText } = error
	const [title, setTitle] = useState('')
	const [anchorEl, setAnchorEl] = useState(null)
	const [openClone, setClone] = useState(false)
	const [openMove, setOpenMove] = useState(false)
	const dispatch = useDispatch()

	const actionMenus = [
		isOwnerOrCreatedBy
			? {
					label: 'Delete',
					icon: 'delete',
					actionName: PERMISSION_TYPE.DELETE,
			  }
			: null,
		isOwnerOrCreatedByOrReporter
			? {
					label: 'Clone',
					icon: 'content_copy',
					actionName: PERMISSION_TYPE.CLONE,
			  }
			: null,
		isOwnerOrCreatedByOrReporter
			? {
					label: 'Move',
					icon: 'drive_file_move',
					actionName: PERMISSION_TYPE.MOVE,
			  }
			: null,
	]

	const fomatedActionItems = _.compact(actionMenus)

	useEffect(() => {
		setTitle(formik?.values?.sticky_title)
	}, [formik.values.sticky_title])

	const handleClickMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const handleTitleChange = (e) => {
		if (_.isEmpty(_.trim(e.target.value))) {
			setError({
				isError: true,
				errorText: 'Please Enter PipeLine Details',
			})
		} else if (_.trim(e.target.value).length > 255) {
			setError({
				isError: true,
				errorText: 'PipeLine title should not be more than 255 characters.',
			})
		} else {
			console.log('_.trim(e.target.value)', _.trim(e.target.value).length > 255)
			setError({
				isError: false,
				errorText: '',
			})
		}
		setTitle(e.target.value)
	}

	const onClickAway = () => {
		if (!isError) {
			setTitleClicked(false)
			formik?.setFieldValue('sticky_title', title)
		}
	}

	const performAction = (actionName) => {
		switch (actionName) {
			case PERMISSION_TYPE.DELETE:
				deleteSticky()
				break
			case PERMISSION_TYPE.CLONE:
				OpenCloneDialog()
				break
			case PERMISSION_TYPE.MOVE:
				OpenMoveDialog()
				break
			default:
				break
		}
		handleCloseMenu()
	}
	const OpenCloneDialog = () => {
		setClone(true)
	}

	const OpenMoveDialog = () => {
		setOpenMove(true)
	}

	const deleteSticky = () => {
		const onSuccess = (resp) => {
			dispatch(hideLoader())
			onClose()
			dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2500))
			dispatch(refreshStickyList(glassCode, ''))
		}
		const onError = (err) => {
			dispatch(showErrorMessage(getErrMsg(err), 'Close', () => dispatch(hideLoader())))
		}
		askDeleteConfirm(
			() => {
				dispatch(showLoader('Deleting pipeline...'))
				dispatch(removeSticky(sessionId, glassCode, stickyCode, onSuccess, onError))
			},
			() => {
				dispatch(hideLoader())
			}
		)
	}
	const askDeleteConfirm = (onConfirm, onCancel) => {
		let askMesg = stickyDetails?.parent_sticky_code
			? 'All the actions will  be removed from the activity feed, and you won’t be able to re-open the pipeline'
			: 'All the actions will  be removed from the activity feed, and you won’t be able to re-open the pipeline. \n Note: Please remove the Subtasks from the pipeline that you need'
		dispatch(showConfirmMessage(askMesg, '', 'Confirm', onConfirm, 'Cancel', onCancel))
	}
	return (
		<Grid container justifyContent='space-between' alignItems='flex-start' className={classes.headerRoot}>
			<Grid item lg={10} sm={10} xs={10}>
				{!titleClicked ? (
					<React.Fragment>
						<Breadcrumbs aria-label='breadcrumb'>
							{stickyDetails?.parent_sticky_code && (
								<Link
									onClick={() => {
										onHeaderClick(stickyDetails?.parent_sticky_code)
									}}
									className={classes.parentSticky}
								>
									{stickyDetails?.parent_sticky_code}
								</Link>
							)}
							<Typography className={classes.subTitle} variant='caption' component='div' color='textSecondary'>
								{stickyDetails?.sticky_code}
							</Typography>
						</Breadcrumbs>
						<div className={classes.titleSec} role='button' onClick={() => (isEditEnabled ? setTitleClicked(true) : false)}>
							<Typography variant='h6' component='h6'>
								{title}
							</Typography>
						</div>
					</React.Fragment>
				) : (
					<TextField
						fullWidth
						name='sticky_title'
						onBlur={onClickAway}
						onChange={handleTitleChange}
						variant='outlined'
						autoFocus={true}
						value={title}
						error={isError}
						helperText={isError && errorText}
						margin='dense'
					/>
				)}
			</Grid>
			<Grid item lg={2} sma={2} xs={2} className={classes.closeIcon}>
				<div className={classes.headerIconSec}>
					{(isOwnerOrCreatedBy || isOwnerOrCreatedByOrReporter) && (
						<IconButton size='small' edge='end' onClick={handleClickMenu}>
							<Icon>more_horiz</Icon>
						</IconButton>
					)}
					<IconButton size='small' edge='end' onClick={onClose}>
						<Icon>close</Icon>
					</IconButton>
				</div>
			</Grid>
			<PopoverMenus
				open={Boolean(anchorEl)}
				id={'1'}
				menus={fomatedActionItems}
				anchorEl={anchorEl}
				onClickAction={performAction}
				onClose={handleCloseMenu}
			/>
			<CloneForm
				open={openClone}
				setForm={setClone}
				glassMembers={glassMembers}
				lanesData={lanesData}
				stickyDetails={stickyDetails}
				glassCode={glassCode}
				stickyCode={stickyCode}
				history={history}
				sessionId={sessionId}
			/>
			<MoveForm
				open={openMove}
				setForm={setOpenMove}
				glassMembers={glassMembers}
				lanesData={lanesData}
				stickyDetails={stickyDetails}
				glassCode={glassCode}
				stickyCode={stickyCode}
				history={history}
				sessionId={sessionId}
			/>
		</Grid>
	)
}

Header.propTypes = {
	onClose: PropTypes.func.isRequired,
	formik: PropTypes.object.isRequired,
	isEditEnabled: PropTypes.bool,
	stickyDetails: PropTypes.object.isRequired,
	sessionId: PropTypes.string,
	glassCode: PropTypes.string.isRequired,
	stickyCode: PropTypes.string.isRequired,
	onHeaderClick: PropTypes.func,
}

export default Header
