import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
import _ from 'lodash'
import { format } from 'date-fns'

const CancelToken = Axios.CancelToken

const params = (param) => {
	// console.log('param', param)
	let productData = []
	// let productID = [];
	let productRule
	let sourceData = []
	let sourceRule
	let sellerData = []
	let sellerRule
	let brandData = []
	let brandRule
	let stockDate = []
	let stockRule
	let startDate = format(_.isString(param?.dateRange?.startDate) ? new Date(param?.dateRange?.startDate) : param?.dateRange?.startDate, 'yyyy-MM-dd')
	let endDate = format(_.isString(param?.dateRange?.endDate) ? new Date(param?.dateRange?.endDate) : param?.dateRange?.endDate, 'yyyy-MM-dd')

	if (param.product_name.length !== 0) {
		param.product_name.forEach((productName) => {
			productData.push({
				condition: 'equals',
				field_name: 'product_name',
				value: [productName],
			})
		})
		productRule = {
			rules: productData,
			condition: 'OR',
		}
	}
	if (!_.isEmpty(param.product_id)) {
		// param.product_id.forEach(prod_id => {
		//     productID.push({
		//         "condition": "equals",
		//         "field_name": "product_id",
		//         "value": [prod_id]
		//     })
		// })
		productRule = {
			// "rules": productID,
			rules: [
				{
					condition: 'equals',
					field_name: 'product_id',
					value: param.product_id,
				},
			],
			condition: 'OR',
		}
	}
	//
	if (param.source.length !== 0) {
		param.source.forEach((productName) => {
			sourceData.push({
				condition: 'equals',
				field_name: 'source',
				value: [productName],
			})
		})
		sourceRule = {
			rules: sourceData,
			condition: 'OR',
		}
	}
	if (param.seller_name.length !== 0) {
		param.seller_name.forEach((sellerName) => {
			sellerData.push({
				condition: 'equals',
				field_name: 'seller_name',
				value: [sellerName],
			})
		})
		sellerRule = {
			rules: sellerData,
			condition: 'OR',
		}
	}

	if (param.brand.length !== 0) {
		param.brand.forEach((brandName) => {
			brandData.push({
				condition: 'equals',
				field_name: 'brand',
				value: [brandName],
			})
		})
		brandRule = {
			rules: brandData,
			condition: 'OR',
		}
	}

	if (param.stock.length !== 0) {
		param.stock.forEach((stockName) => {
			stockDate.push({
				condition: 'equals',
				field_name: 'stock',
				value: [stockName],
			})
		})
		stockRule = {
			rules: stockDate,
			condition: 'OR',
		}
	}

	const productNameFxn = () => {
		if (productRule !== [] && productRule !== null) {
			return productRule
		}
	}
	const retailerNameFxn = () => {
		if (sourceRule !== [] && sourceRule !== null) {
			return sourceRule
		}
	}
	const resellerNameFxn = () => {
		if (sellerRule !== [] && sellerRule !== null) {
			return sellerRule
		}
	}
	const brandNameFxn = () => {
		if (brandRule !== [] && brandRule !== null) {
			return brandRule
		}
	}
	const stockNameFxn = () => {
		if (stockRule !== [] && stockRule !== null) {
			return stockRule
		}
	}
	const onlyCounterfeitsFxn = () =>
		_.get(param, 'isOnlyCounterfeits', false) === true
			? {
					rules: [{ condition: 'equals', field_name: 'counterfeit', value: ['Yes'] }],
					condition: 'OR',
			  }
			: {}
	const group = [
		{
			rules: [
				{
					condition: 'equals or greater than',
					field_name: 'product_discount',
					value: [`${param.discount[0]}`],
				},
				{
					condition: 'equals or less than',
					field_name: 'product_discount',
					value: [`${param.discount[1]}`],
				},
			],
			condition: 'AND',
		},
		{
			rules: [
				{
					condition: 'between',
					field_name: 'crawled_timestamp',
					value: [`${startDate} 00:00:00.000`, `${endDate} 23:59:59.999`],
				},
			],
			condition: 'OR',
		},
		onlyCounterfeitsFxn(),
		productNameFxn(),
		retailerNameFxn(),
		resellerNameFxn(),
		stockNameFxn(),
		brandNameFxn(),
	]

	let query = {
		ds_id: _.get(param, 'ds_id', ''), //"5ea932ef2e2241589d0b66d1",
		graph_category: 'table',
		query_engine_type: 'dremio',
		filter_definitions: [
			{
				ds_id: _.get(param, 'ds_id', ''), //'5ef1845b2e224129ef1740d4',//"5ea932ef2e2241589d0b66d1",
				groups: group.reduce((acc, currentRule) => {
					if (!_.isNil(currentRule) && !_.isEmpty(currentRule)) {
						acc.push(currentRule)
					}
					return acc
				}, []),
				condition: 'AND',
			},
		],
		order_definitions: [
			{
				field_name: 'counterfeit',
				condition: 'DESC',
			},
			{
				field_name: 'product_discount',
				condition: 'DESC',
			},
		],
		dimensions: [],
		measures: [],
		table_view: true,
		page_number: parseInt(param.page),
		page_row_count: parseInt(param.limit),
	}
	return query
}

const api = {
	getProductsTableData: (query, cancelExecutor) => {
		return Axios.post(API_ENDPOINTS.PRODUCTS_TABLE, params(query), {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
}

export default api
