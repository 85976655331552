import React, { useState, useEffect } from 'react'
import { makeStyles, Accordion, AccordionSummary, Slider, Icon, Grid, Typography, AccordionDetails } from '@material-ui/core'
// import SearchBox from '../../PainSearchBox';
// import { useDispatch } from 'react-redux';
import _ from 'lodash'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
	expansionPanelRoot: {
		borderBottom: 0,
		background: 'transparent',
		boxShadow: 'none',
		'&.Mui-expanded': {
			margin: theme.spacing(0.5, 0),
		},
		'&:not(:last-child)': {
			// borderBottom: '1px solid rgba(0, 0, 0, .125)',
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 0,
		},
		'& .MuiAccordionSummary-expandIcon': {
			color: theme.palette.almostBlack[600],
		},
	},
	expanded: {},
	filterPanelTitleContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	resetSpan: {
		padding: theme.spacing(0.5),
		color: theme.palette.almostBlack[800],
		borderRadius: '5px',
		transition: 'background 0.3s',
		// fontFamily: '"Poppins"',
		fontSize: theme.typography.pxToRem(10),
		'&:hover': {
			background: `${theme.palette.primary.main}11`,
			transition: 'background 0.3s',
		},
	},
	root: {
		padding: theme.spacing(0),
		'&.Mui-expanded': {
			minHeight: 'unset',
			// margin: theme.spacing(0.5,0)
		},
		'& .MuiIconButton-colorPrimary:hover': {
			backgroundColor: 'transparent',
		},
		'& .MuiSvgIcon-root': {
			// color: `${theme.palette.primary.dark}`
		},
		'& .MuiTypography-body1': {
			lineHeight: 0,
			fontWeight: 400,
		},
		'& .MuiFormGroup-row': {
			width: '100%',
			justifyContent: 'space-between',
		},
	},
	muiPanelSummaryRoot: {
		'&.Mui-expanded': {
			margin: theme.spacing(0.5, 0),
		},
	},
	iconRoot: {
		minWidth: 'auto',
	},
	listRoot: {},
	listItemRoot: {
		paddingTop: theme.spacing(0),
		paddingBottom: theme.spacing(0),
		paddingLeft: theme.spacing(1),
		cursor: 'pointer',
		alignItems: 'center',
	},
	filterPanelHeading: {
		color: theme.palette.almostBlack[800],
		letterSpacing: theme.typography.pxToRem(0.8),
	},
	listText: {
		color: theme.palette.almostBlack[800],
		textTransform: 'uppercase',
		'& .MuiTypography-body1': {
			lineHeight: 0,
			fontWeight: 400,
		},
	},
	searchContainer: {
		padding: theme.spacing(1, 0),
	},
	capitaliseText: {
		textTransform: 'capitalize !important',
	},
	sliderDivLabels: {
		paddingBottom: 6,
	},
	flexContainer: {
		display: 'flex',
		width: '100%',
	},
	paddingForSlider: {
		padding: theme.spacing(0, 0.5),
	},
	flexCell: {
		flex: 1,
	},
	textRight: {
		textAlign: 'right',
	},
	rangeLabels: {
		color: theme.palette.almostBlack[600],
	},
	infoText: {
		fontSize: theme.typography.pxToRem(10),
		fontWeight: '400',
		color: theme.palette.almostBlack[500],
	},
}))

const RangeFilter = (props) => {
	const { title, rangeSelected, min, max, step, onChangeCommitted, handleClearThisFilter, minTitle, maxTitle, rangePrefix, rangeSuffix, infoText } =
		props

	const classes = useStyles()
	const [isExpanded, setIsExpanded] = useState(false)

	const [value, setValue] = useState(rangeSelected)

	const handleChange = (e, newValue) => {
		setValue(newValue)
	}

	const handleResetClick = (e) => {
		e.stopPropagation()
		// console.log('clicked');
		handleClearThisFilter()
	}

	useEffect(() => setValue(rangeSelected), [rangeSelected])

	const handleChangeCommitted = () => {
		onChangeCommitted(value)
	}

	return (
		<>
			<Accordion
				expanded={isExpanded}
				classes={{
					root: classes.expansionPanelRoot,
					expanded: classes.expanded,
				}}
				onChange={() => setIsExpanded(!isExpanded)}
				elevation={0}
			>
				<AccordionSummary classes={{ root: classes.root, content: classes.muiPanelSummaryRoot }} expandIcon={<Icon>keyboard_arrow_down</Icon>}>
					<div className={classes.filterPanelTitleContainer}>
						<Typography className={classes.filterPanelHeading} variant='subtitle2'>
							{title}
						</Typography>
						{!_.isEqual(
							_.sortBy(rangeSelected, (e) => e),
							[min, max]
						) ? (
							<Typography variant='caption' className={classes.resetSpan} component='span' onClick={handleResetClick}>
								RESET
							</Typography>
						) : null}
					</div>
				</AccordionSummary>
				<AccordionDetails classes={{ root: classes.root }}>
					<Grid container>
						<div className={classnames(classes.flexContainer, classes.sliderDivLabels)}>
							<div className={classes.flexCell}>
								<Typography className={classes.rangeLabels} variant='caption'>
									{minTitle}
								</Typography>
								<Typography variant='body2'>
									{rangePrefix} {value[0]}
									{rangeSuffix}
								</Typography>
							</div>
							<div>
								<Typography className={classes.rangeLabels} variant='caption'>
									{maxTitle}
								</Typography>
								<Typography className={classes.textRight} variant='body2'>
									{rangePrefix} {value[1]} {rangeSuffix}
								</Typography>
							</div>
						</div>
						<div className={classnames(classes.flexContainer, classes.paddingForSlider)}>
							<Slider
								onChange={handleChange}
								onChangeCommitted={handleChangeCommitted}
								step={step}
								value={value}
								color='secondary'
								min={min}
								max={max}
							/>
						</div>
						<div className={classnames(classes.flexContainer, classes.paddingForSlider)}>
							{!_.isNil(infoText) ? (
								<Typography variant='caption' className={classes.infoText}>
									{infoText}
								</Typography>
							) : null}
						</div>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</>
	)
}

export default RangeFilter
