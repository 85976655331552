import React from 'react'
// import AmazonDB from "./Amazon";
import Hive from './Hive'
import MongoDB from './MongoDB'
import MySQL from './MySQL'
import Oracle from './Oracle'
import PostgreSQL from './PostgreSQL'
import { DATA_CONNECTION_TYPES } from '../../../constants'
import Elastic from './Elastic'
import AmazonRedShift from './AmazonRedShift'
import AmazonElasticSearch from './AmazonElasticSearch'
import MicroSoft from './MicroSoft'
import MapRFS from './MapRFS'
import Azure from './Azure'
import HDFS from './HDFS'
import NAS from './NAS'
import Facebook from './Facebook'
import Twitter from './Twitter'
import LinkedIn from './LinkedIn'
import Instagram from './Instagram'
import GoogleDrive from './GoogleDrive'
import GBQ from './GBQ'
import Gmail from './Gmail'
import Outlook from './Outlook'
import Dropbox from './Dropbox'
import AmazonS3 from './AmazonS3'
import CSV from './CSV'
import GoogleAnalytics from './GoogleAnalytics'
import SocialMediaApps from './SocialMediaApps'
import MarketPlaceApps from './MarketPlaceApps'

const ConnectionForm = ({
	formRef,
	initialFormData,
	connectionType,
	isViewMode,
	isEditMode,
	onSubmitForm,
	onSubmitAppForm,
	onVerifyForm,
	onUploadFile,
	dialogOpen,
	handleDialogClose,
	dialogProps,
}) => {
	switch (connectionType) {
		case DATA_CONNECTION_TYPES.HIVE:
			return <Hive ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
		case DATA_CONNECTION_TYPES.MONGO:
			return <MongoDB ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
		case DATA_CONNECTION_TYPES.MYSQL:
			return <MySQL ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
		case DATA_CONNECTION_TYPES.ORACLE:
			return <Oracle ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
		case DATA_CONNECTION_TYPES.POSTGRES:
			return <PostgreSQL ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
		case DATA_CONNECTION_TYPES.ELASTIC:
			return <Elastic ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
		case DATA_CONNECTION_TYPES.REDSHIFT:
			return <AmazonRedShift ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
		case DATA_CONNECTION_TYPES.AMAZONELASTIC:
			return (
				<AmazonElasticSearch ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
			)
		case DATA_CONNECTION_TYPES.MSSQL:
			return <MicroSoft ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
		case DATA_CONNECTION_TYPES.MAPRFS:
			return <MapRFS ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
		case DATA_CONNECTION_TYPES.ADL:
			return <Azure ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
		case DATA_CONNECTION_TYPES.HDFS:
			return <HDFS ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
		case DATA_CONNECTION_TYPES.NAS:
			return <NAS ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />

		case DATA_CONNECTION_TYPES.FACEBOOK:
			return <Facebook ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />

		case DATA_CONNECTION_TYPES.TWITTER:
			return <Twitter ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />

		case DATA_CONNECTION_TYPES.LINKEDIN:
			return <LinkedIn ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />

		case DATA_CONNECTION_TYPES.INSTAGRAM:
			return <Instagram ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />

		case DATA_CONNECTION_TYPES.GDrive:
			return (
				<GoogleDrive
					ref={formRef}
					initialFormData={initialFormData}
					pagination={false}
					isViewMode={isViewMode}
					// onSubmitForm={onSubmitAppForm}
					onSubmitForm={onSubmitForm}
					onVerifyForm={onVerifyForm}
					dialogOpen={dialogOpen}
					dialogProps={dialogProps}
					handleDialogClose={handleDialogClose}
					isEditMode={isEditMode}
				/>
			)

		case DATA_CONNECTION_TYPES.GMAIL:
			return <Gmail ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />

		case DATA_CONNECTION_TYPES.OUTLOOK:
			return <Outlook ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />

		case DATA_CONNECTION_TYPES.GBQ:
			return (
				<GBQ
					ref={formRef}
					initialFormData={initialFormData}
					pagination={false}
					isViewMode={isViewMode}
					// onSubmitForm={onSubmitAppForm}
					onSubmitForm={onSubmitForm}
					onVerifyForm={onVerifyForm}
					dialogOpen={dialogOpen}
					dialogProps={dialogProps}
					handleDialogClose={handleDialogClose}
					isEditMode={isEditMode}
				/>
			)

		case DATA_CONNECTION_TYPES.DROPBOX:
			return (
				<Dropbox
					ref={formRef}
					initialFormData={initialFormData}
					pagination={false}
					isViewMode={isViewMode}
					// onSubmitForm={onSubmitAppForm}
					onSubmitForm={onSubmitForm}
					onVerifyForm={onVerifyForm}
					dialogOpen={dialogOpen}
					dialogProps={dialogProps}
					handleDialogClose={handleDialogClose}
					isEditMode={isEditMode}
				/>
			)

		case DATA_CONNECTION_TYPES.AMAZONS3:
			return (
				<AmazonS3
					ref={formRef}
					initialFormData={initialFormData}
					pagination={false}
					isViewMode={isViewMode}
					// onSubmitForm={onSubmitAppForm}
					onSubmitForm={onSubmitForm}
					onVerifyForm={onVerifyForm}
					dialogOpen={dialogOpen}
					dialogProps={dialogProps}
					handleDialogClose={handleDialogClose}
					isEditMode={isEditMode}
				/>
			)

		case DATA_CONNECTION_TYPES.CSV:
			return (
				<CSV
					ref={formRef}
					initialFormData={initialFormData}
					pagination={false}
					isViewMode={isViewMode}
					onSubmitForm={onSubmitForm}
					onUploadFile={onUploadFile}
				/>
			)

		case DATA_CONNECTION_TYPES.GA:
			return (
				<GoogleAnalytics
					ref={formRef}
					initialFormData={initialFormData}
					pagination={false}
					isViewMode={isViewMode}
					onSubmitForm={onSubmitForm}
					onVerifyForm={onVerifyForm}
					dialogOpen={dialogOpen}
					dialogProps={dialogProps}
					handleDialogClose={handleDialogClose}
					isEditMode={isEditMode}
				/>
			)

		case DATA_CONNECTION_TYPES.SOCIALMEDIAAPPS:
			return (
				<SocialMediaApps ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
			)

		case DATA_CONNECTION_TYPES.MARKETPLACEAPPS:
			return (
				<MarketPlaceApps ref={formRef} initialFormData={initialFormData} pagination={false} isViewMode={isViewMode} onSubmitForm={onSubmitForm} />
			)

		default:
			return null
	}
}

export default ConnectionForm
